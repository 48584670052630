import './App.css';
import Firstpage from './Pages/Firstpage';

function App() {
  return (
    <div className=" text-white bg-black">
<Firstpage/>
    </div>
  );
}

export default App;
